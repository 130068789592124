import React from 'react'
import '../Styles/Header.css';

export default function Header() {
    return (
        <div className="header">
            <img width="300" alt="Kalliotekniikka" src="logo.png" className="img-responsive wp-image-94 disable-lazyload" />
        </div>
    )
}
