import React from 'react'
import Popup from 'reactjs-popup'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function InfoPopup( {popupName, popupText} ) {
    return (
        <Popup trigger={<button className="popup-button"> {popupName} </button>} modal>
            {close => (
                <>
                    
                    <div className="modal">
                        <div className="modal-content">
                            <ReactMarkdown children={popupText} remarkPlugins={[remarkGfm]} />
                        </div>
                        <div className="actions">
                            <button
                                className="popup-button"
                                onClick={() => {
                                console.log('modal closed ');
                                close();
                                }}
                            >
                                Sulje
                            </button>
                        </div>
                    </div>
                    <div className="dimmer" onClick=
                        {() => {
                            console.log('modal closed ');
                            close();
                        }}
                    />
                </>
            )}
        </Popup>
    )
}
