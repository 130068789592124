import axios from 'axios'

const baseURL = '/api/v1'; //'https://615aad214a360f0017a811dc.mockapi.io/'

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});

export default axiosInstance
