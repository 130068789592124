import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from "react-router-dom"
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no"></meta>
      <Router>
        <App />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
