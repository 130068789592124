import React, { useEffect, useState } from 'react'
import { useFieldArray, useFormState } from 'react-hook-form';

import Neighbour from './Neighbour';

export default function SiteDetails({control, register, setValue, getValues, watch, errors, neighbour_check_fee}) {
    
    const [neighboursBySelf, setneighboursBySelft] = useState("not_known");

    const { fields, append, remove } = useFieldArray({
        control,
        name: "neighbours"
    });
    
    const site_matches_basicdetails = watch("site_matches_basicdetails");
    
    useEffect(() => {
        register("neighbours_unknown")
    }, [register]);

    useEffect(() => {
        if(fields.length > 0){
            setneighboursBySelft("byself");
        }
    }, [fields]);


    function handleRadioChange(e) {
        setneighboursBySelft(e);
        if(e === "byself"){
            setValue('neighbours_unknown', false, { shouldDirty: true })
        }else{
            setValue('neighbours_unknown', true, { shouldDirty: true })
        }
    }

      
    const neighbours = (
        <div className='form-column'>
            <div className="title-div"><h2> Naapureiden tiedot</h2></div>
            <button
                type="button"
                className='add-button'
                onClick={() => {
                    append({
                        "first_name":"",
                        "last_name":"",
                        "email":"",
                        "phone":"",
                        "street_address": "",
                        "need_to_check": false
                    });
                }}>
                Lisää naapuri
            </button>
            {fields.map((item, index) => {
                return (
                        <Neighbour 
                            key={index}
                            neighbour_check_fee={neighbour_check_fee}
                            nestIndex={index}
                            {...{control, register, setValue, getValues, watch, errors, remove}}
                        />
                )    
            })}
        </div> 
    )
                

    return (
        <>
            <div className='form-column'>
                <h2>Kohteen tiedot</h2>
                <div>
                    <input
                        name="site_matches_basicdetails"
                        id='site_matches_basicdetails'
                        type="checkbox"
                        {...register("site_matches_basicdetails")}
                        className="checkbox-input"
                    />
                    <label htmlFor="site_matches_basicdetails">Sama kuin ilmoittajan</label>
                </div>
                {!site_matches_basicdetails && <SiteInputs {...{control, register, setValue, getValues, watch, errors}}/>}
                
                <h3>Naapureiden tiedot</h3>
                <input 
                    name='check-neighbours'
                    type="radio"
                    id='send_byself'
                    className="radio-input"
                    onChange={event => handleRadioChange(event.target.value)}
                    value='byself'
                    checked={neighboursBySelf === "byself" ? true : false} />
                <label htmlFor='send_byself' >Toimitan tiedot itse</label>
                <input
                    name='check-neighbours'
                    type="radio"
                    id='not_known'
                    className="radio-input"
                    onChange={event => handleRadioChange(event.target.value)}
                    value="not_known"
                    checked={neighboursBySelf === "not_known" ? true : false} />
                <label htmlFor='not_known' >Ei tiedossa</label>
                
                {neighboursBySelf !== "byself" ? 
                    <div>
                        {errors.no_of_neighbours && <span className="errorMsg">Tämä tieto on pakollinen</span>}
                        <input
                        name="no_of_neighbours"
                        {...register("no_of_neighbours", { 
                            required: true })} 
                        type="number" 
                        placeholder="Naapureiden lukumäärä:*"
                        className="name-input"
                        />
                        
                    </div> : ''}
                    <p className="side-note">Mikäli naapuritietoja pitää tarkistaa, veloitetaan tietojen tarkistamisesta lukumäärästä riippumaton  maksu {neighbour_check_fee}&nbsp;€</p>
            </div>
            {neighboursBySelf === "byself" ? neighbours : ''}
        </>
    )
}

function SiteInputs({control, register, setValue, getValues, watch, errors}) {
      
    return (
      <div>
            {errors.target_address && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                name="target_address"
                {...register("target_address", { 
                    required: true })} 
                type="text" 
                placeholder="Kadunnimi:*"
                className="name-input"
            />
            {errors.target_street_number && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                name="target_street_number"
                {...register("target_street_number", { 
                    required: false })} 
                type="text" 
                placeholder="Katunumero: (jätä tyhjäksi jos ei tiedossa)"
                className="name-input"
            />

            {errors.target_zip && <span className="errorMsg">Tämä tieto on pakollinen</span>}   
            <input
                name="target_zip"
                {...register("target_zip", { 
                    required: true,
                    maxLength: 5,
                    minLength: 5
                })} 
                type="text"
                placeholder="Postinumero:*"
                className="name-input"
            />

            {errors.target_municipality && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                name="target_municipality"
                {...register("target_municipality", { 
                    required: true })} 
                type="text"
                placeholder="Kunta:*"
                className="name-input"
            />
      </div>
    );
  }


