import React from 'react'

export default function Neighbour({nestIndex, control, register, setValue, getValues, watch, errors, remove, neighbour_check_fee}) {
    
    const need_to_checked = watch(`neighbours.${nestIndex}.need_to_check`);
    // {need_to_checked ? <p>Tietojen tarkistamisesta peritään {neighbour_check_fee} €</p> : ''}
    return (
        <div className='neighbour-box'>
            <div className='neighbour_title_div'>
            <div className="title-div"><h2 className='neighbour_title'> Naapuri {nestIndex+1} </h2></div>
                <button className='add-button' type="button" onClick={() => remove(nestIndex)}>
                    Poista
                </button>
            </div>
            
            {errors.neighbours?.[nestIndex]?.first_name && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                name='first_name'
                id='first_name'
                {...register(`neighbours.${nestIndex}.first_name`, { 
                    required: !need_to_checked })} 
                type="text" 
                placeholder="Etunimi:*"
                className="name-input"
            />
            {errors.neighbours?.[nestIndex]?.last_name && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                name='last_name'
                {...register(`neighbours.${nestIndex}.last_name`, { 
                    required: !need_to_checked })} 
                type="text" 
                placeholder="Sukunimi:*"
                className="name-input"
            />
            {errors.neighbours?.[nestIndex]?.email && <span className="errorMsg">Virheellinen sähköpostiosoite</span>}
            <input
                name='email'
                {...register(`neighbours.${nestIndex}.email`, 
                    {
                        required: false,
                        pattern: /^\S+@\S+$/i
                    })} 
                type="text" 
                placeholder="Sähköposti:"
                className="name-input"
            />
            {errors.neighbours?.[nestIndex]?.phone && <span className="errorMsg">Puhelinnumero muodossa 0401234567</span>}
            <input
                name='phone'
                {...register(`neighbours.${nestIndex}.phone`, { 
                    required: !need_to_checked,
                    pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
                })} 
                type="text" 
                placeholder="Puhelinnumero:*"
                className="name-input"
            />
            {errors.neighbours?.[nestIndex]?.street_address && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                name='street_address'
                {...register(`neighbours.${nestIndex}.street_address`, { 
                    required: !need_to_checked })} 
                type="text" 
                placeholder="Katuosoite:*"
                className="name-input"
            />
            <input
                name='need_to_check'
                id='need_to_check'
                {...register(`neighbours.${nestIndex}.need_to_check`)} 
                type="checkbox" 
                className="checkbox-input"
            />
            <label htmlFor='need_to_check'>Ei tiedossa</label>
            
        </div>
    );
}
