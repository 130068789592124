import React, { useState, useEffect } from 'react';
import { useForm, useFormState  } from 'react-hook-form';
import axiosInstance from '../AxiosApi';

import '../Styles/Form.css';
import LoadingIndicator from './LoadingIndicator';
import BasicDetails from './Form2/BasicDetails';
import SiteDetails from './Form2/SiteDetails';
import FloatingFooter from './Form2/FloatingFooter';

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


export default function Form2({id}) {    
    
    const [error, setError] = useState(false);

    const [defaultValues, setDefaultValues] = useState({
        id:'',
        first_name: '',
        last_name: '',
        email: '',

        phone: '',
        address: '',
        zip: '',
        municipality: '',

        site_matches_basicdetails: false,
        target_address: '',
        target_street_number: '',
        target_zip: '',
        target_municipality: '',
        neighbours_unknown: true,
        neighbours: [],

        cost_estimate: '',
        order_submitted: false

    });

    const [markDownTexts, setMarkDownTexts] = useState({
        error_email: process.env.REACT_APP_KT_DEFAULT_SUPPORT_EMAIL,
        info_text : '',
        neighbour_check_fee : 50.0,
        infotexts : [
            {
                name: ' ',
                content: ' '
            }
        ]
    })

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        reset,
        setValue,
        watch
      } = useForm({
        defaultValues
      });
    
      const { isDirty, dirtyFields } = useFormState({
        control
      });
    

    const [submitted, setSubmitted] = useState(false);
    const [canBeFinished, setCanBeFinished] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(isDirty){
            setCanBeFinished(false)
        } 
    },[isDirty])

    const onSubmitSave = data => {
        handleSubmit((data)=>{
            console.log(data);
            console.log(id);
            data.cost_estimate = null
            setLoading(true)

            axiosInstance.put('/quotes/'+id+'/', data)
            .then(function (response) {
                console.log(response);
                
                resetData(response.data);
                setLoading(false);
                setCanBeFinished(true)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
                setError(true);
            });
        })()
    }
    
    const onSubmitQuote = data => {
        handleSubmit((data)=>{
            console.log('onSubmitQuote')
            data.quote_submitted = true;
            console.log(data)

            setLoading(true)

            axiosInstance.put('/quotes/'+id+'/', data)
            .then(function (response) {
                console.log(response);
                
                resetData(response.data);
                setLoading(false);
                setSubmitted(true);
            })
            .catch(function (error) {
                console.log(error);
                setError(true)
                setLoading(false);
            });
        })()
  
    }

    const resetData = (gotData) => {
        let data = gotData;
        if(data.neighbours === null){
            data.neighbours = [];
        }
        setDefaultValues(data);
        reset(data);
    }


    useEffect(async() => {
        setLoading(true)

        await axiosInstance.get('/quotes/'+id+'/')
          .then(function (response) {
            console.log(response);

            if (response.data.site_matches_basicdetails === null){
                response.data.site_matches_basicdetails = false;
            }
            if (response.data.neighbours_unknown === null){
                response.data.neighbours_unknown = true;
            }

            resetData(response.data);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
            setError(true);
          });
        
        // Get texts
        axiosInstance.get('/info/')
          .then(function (response) {
            console.log(response);

            setMarkDownTexts(response.data)
            
          })
          .catch(function (error) {
            console.log(error);
            setError(true);
        });

    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    if(error){
        let message = `Odottamaton virhe, yritä uudelleen tai ota yhteyttä ${markDownTexts.error_email}`
        return(
            <div className="form-container">
                <h1>
                    Virhe!
                </h1>
                <ReactMarkdown children={message} remarkPlugins={[remarkGfm]} />
            </div>
        )
    }

    if(submitted === false){
        return(
            <>
                <div className="push-down">
                    <form>
                        <div className="form-container">
                            <div className="form-header">
                                <h1>Tarjouslaskuri</h1>
                                <p className="side-note">Täytä vähintään tähdellä * merkityt tiedot.<br />Tallentamalla lomakkeen voit jatkaa sen täyttämistä myöhemmin sähköpostistasi löytyvän linkin kautta.</p>
                            </div>
                            {loading ? <LoadingIndicator /> :
                                <>
                                    <div className='form-column'>
                                        <BasicDetails id="basic-details" {...{ control, register, defaultValues, getValues, setValue, errors }}/>
                                        <p className="side-note">{markDownTexts.info_text}</p>
                                    </div>
                                    
                                    <SiteDetails 
                                        {...{ control, register, defaultValues, getValues, setValue, errors, watch }}
                                        neighbour_check_fee = {markDownTexts.neighbour_check_fee}/>
                                    
                                </>}
                        </div>
                        
                    </form>
                </div>
                <FloatingFooter 
                        cost_estimate={defaultValues.cost_estimate}
                        canBeFinished={canBeFinished}
                        {...{register, handleSubmit}}
                        onSubmitSave = {onSubmitSave}
                        onSubmitSend = {onSubmitQuote}
                        infoTexts = {markDownTexts.infotexts}
                />
                
            </>
        );
    }

    return(
        <div className="form-container">
            <h1>
            Kiitos! <br/>
            Tarkkaile sähköpostiasi!
            </h1>
        </div>
    );
}
