import React from 'react'

export default function BasicDetails({ control, register, setValue, getValues, errors }) {
      
    return (
        <>
            <h2>Perustiedot</h2>
            {errors.first_name && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                type="text" 
                {...register("first_name", { 
                    required: true })} 
                placeholder="Etunimi:*"
                className="name-input"
            />
            {errors.last_name && <span className="errorMsg">Tämä tieto on pakollinen</span>} 
            <input
                type="text" 
                {...register("last_name", { 
                    required: true })} 
                placeholder="Sukunimi:*"
                className="name-input"
            />
            
            {errors.email && <span className="errorMsg">Virheellinen sähköpostiosoite</span>}
            <input
                type="text"
                {...register("email", {
                    required: true, //pattern:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })}
                placeholder="Sähköpostiosoite:*"
                className="name-input"
            />

            {errors.phone && <span className="errorMsg">Puhelinnumero muodossa 0401234567</span>}
            <input
                type="text"
                {...register("phone", {
                    required: true, //pattern: /^([\d()+\- ]+$)(?<!(\D))/g
                    pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
                })}
                placeholder="Puhelinnumero:*"
                className="name-input"
            />

            {errors.address && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                type="text"
                {...register("address", {
                    required: true
                })}
                placeholder="Katuosoite:*"
                className="name-input"
            />

            {errors.zip && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                type="text"
                {...register("zip", {
                    required: true,
                    maxLength: 5,
                    minLength: 5
                })}
                placeholder="Postinumero:*"
                className="name-input"
            />

            {errors.municipality && <span className="errorMsg">Tämä tieto on pakollinen</span>}
            <input
                type="text"
                {...register("municipality", {
                    required: true
                })}
                placeholder="Kunta:*"
                className="name-input"
            />
           
        </>
    )
}


