import React from 'react'

import InfoPopup from './InfoPopup'

import './../../Styles/Footer.css'

export default function FloatingFooter( { register, handleSubmit, cost_estimate, canBeFinished, onSubmitSave, onSubmitSend, infoTexts} ) {
    return (
        <div className='sticky-bottom'>
            <div className="footer">
                <div className='price'>
                    <h1>Hinta-arvio</h1>
                    <h2>{cost_estimate != null ? cost_estimate + ' €' : 'Saat hinta-arvion tietojen täyttämisen jälkeen'}</h2>
                </div>
                <button className='save-button'  onClick={onSubmitSave}>Tallenna ja laske hinta</button>
                {canBeFinished ?
                    <button onClick={onSubmitSend}>Lähetä tarjouspyyntö</button>
                    :
                    <button disabled onClick={onSubmitSend}>Lähetä tarjouspyyntö</button>
                }
                <div className="footer-buttons">
                    {infoTexts.map((item) => {
                        return (
                            <InfoPopup
                                popupName={item.name}
                                popupText={item.content}
                                key={item.name}
                            />
                        )    
                    })}
                </div>
            </div>
        </div>
    )
}
