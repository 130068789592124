import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axiosInstance from '../AxiosApi';

import '../Styles/Form.css';
import LoadingIndicator from './LoadingIndicator';
import InfoPopup from './Form2/InfoPopup';

function Form1() {    

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [filled, setFilled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [markDownTexts, setMarkDownTexts] = useState({
        error_email: process.env.REACT_APP_KT_DEFAULT_SUPPORT_EMAIL,
        info_text : '',
        neighbour_check_fee : 50.0,
        infotexts : [
            {
                name: ' ',
                content: ' '
            }
        ]
    })

    useEffect(async() => {
        axiosInstance.get('/info/')
          .then(function (response) {
            console.log(response);

            setMarkDownTexts(response.data)
            
          })
          .catch(function (error) {
            console.log(error);
            setError(true);
        });

    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = data => {
        console.log(data);
        setLoading(true)

        axiosInstance.post('quotes/', {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email
          })
          .then(function (response) {
            console.log(response);
            if(response.status === 201){
                setFilled(true);
            }else{
                setError(true);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setError(true)
            setLoading(false);
          });

    }

    if(error){
        return(
            <div className="form-container">
                <h1>
                    Virhe!
                </h1>
                <span>
                    Odottamaton virhe, yritä uudelleen tai ota yhteyttä <a href={`mailto:${process.env.REACT_APP_SECRET_NAME}`}>{process.env.REACT_APP_KT_DEFAULT_SUPPORT_EMAIL}</a>
                </span>
            </div>
        )
    }

    if(!filled){
        return(
            <div className="form-container">
                <div className='form-column'>
                    <h1>
                    Pyydä tarjous
                    </h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-field">
                            {errors.firstName && <span className="errorMsg">Tämä tieto on pakollinen</span>}
                            <input
                                type="text" 
                                {...register("firstName", { 
                                    required: true })} 
                                placeholder="Etunimi:*"
                                className="name-input"
                            />
                        </div>
                        {errors.lastName && <span className="errorMsg">Tämä tieto on pakollinen</span>} 
                        <input
                            type="text" 
                            {...register("lastName", { 
                                required: true })} 
                            placeholder="Sukunimi:*"
                            className="name-input"
                        />
                        
                        {errors.email && <span className="errorMsg">Virheellinen sähköpostiosoite</span>}
                        <input
                            type="text"
                            {...register("email", {
                                required: true,//pattern:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                pattern: /^\S+@\S+$/i
                            })}
                            placeholder="Sähköpostiosoite:*"
                            className="name-input"
                        />
                        
                        {loading ? <LoadingIndicator /> : <button type="submit">Pyydä tarjous</button>}
                    </form>
                </div>
                <div className="info-texts">
                    {markDownTexts.infotexts.map((item) => {
                        return (
                            <InfoPopup
                                popupName={item.name}
                                popupText={item.content}
                                key={item.name}
                            />
                        )    
                    })}
                </div>
            </div>
        );
    }
    return(
        <div className="form-container">
            <h1>
            Kiitos! <br/>
            Tarkkaile sähköpostiasi!
            </h1>
        </div>
    );

}
export default Form1;