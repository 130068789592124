import './Styles/App.css';
import {useParams, Switch} from "react-router-dom";

import Form1 from './Components/Form1';
import Form2 from './Components/Form2';
import Header from './Components/Header';

function Form2WithSlug() {
  const slug = useParams();
  return <Form2 id={slug.id} />;
}


function App() {
  const Route = require("react-router-dom").Route;

  return (
    <div className="App">
      <div className="container">
        <Header />
        <Switch>
          <Route path="/:id">
            <Form2WithSlug />
          </Route>
          <Route>
            <Form1/>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
